
import { Component, Vue, Prop } from "vue-property-decorator";
import { formatCurrency, formatDate } from "@/utils/formatters";
import { FormTextarea, FormBase, FormError, FormInput, FormSelect, FormMoney, FormPhone } from "@/components/forms";
import countries from "@/assets/json/countries.json";
import { titleOptions, suspensiveConditionOptions, SelectOption, isoCountriesCodes } from "@/utils/select-options";
import { mask } from "vue-the-mask";
import { bus } from "@/main";

@Component({
    components: {
        ConnectivePackagePreview: () => import("@/components/dossier/bids/connective-package-preview.vue"),
        FormInput,
        FormTextarea,
        FormPhone,
        FormSelect,
        FormMoney,
        FormBase,
        FormError,
    },
    directives: { mask },
})
export default class DossierTabPartBidRow extends Vue {
    @Prop({ required: true }) bid!: Bid;

    @Prop({ default: false }) canApprove!: boolean;
    @Prop({ default: false }) canReject!: boolean;
    @Prop({ default: false }) canUndoReject!: boolean;
    @Prop({ default: false }) canUnveil!: boolean;

    open = false;

    bus = bus;

    countries = countries;

    titleOptions = titleOptions;

    suspensiveConditionOptions = suspensiveConditionOptions;

    isoCountriesCodes = isoCountriesCodes;

    formatCurrency = formatCurrency;

    formatDate = formatDate;

    notaryOptions: SelectOption[] = [];

    suspensiveConditions: boolean | null = false;

    generateTooltip(content: string) {
        return { content: content, offset: [0, 12], delay: { show: 500, hide: 100 }, placement: "top" };
    }

    get isCompany() {
        return !!this.bid.company_details;
    }
}
